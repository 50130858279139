import './_footer.scss';
// import LogoLabel from '../../assets/icons/logo-label.png';
import LogoLabel from '../../assets/icons/sporti-logo-label.png';
// Social media icons
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook-fill.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter-fill.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/icons/linkedin-fill.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram-line.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube-fill.svg';
import { ReactComponent as PinterestIcon } from '../../assets/icons/pinterest-line.svg';
import { ReactComponent as RedditIcon } from '../../assets/icons/reddit-line.svg';

const socialMediaIcons = [
  {
    Icon: YoutubeIcon,
    url: 'https://www.youtube.com/channel/UCjGELQ2Cn2AdDhR3pI_VA6g',
  },
  {
    Icon: LinkedinIcon,
    url: 'https://www.linkedin.com/company/sporforya/?originalSubdomain=es',
  },
  { Icon: TwitterIcon, url: 'https://twitter.com/sporforya?lang=es' },
  { Icon: InstagramIcon, url: 'https://www.instagram.com/sporforya/?hl=es' },
  { Icon: FacebookIcon, url: 'https://www.facebook.com/sporforya' },
  { Icon: PinterestIcon, url: 'https://www.pinterest.es/sporforya/' },
  { Icon: RedditIcon, url: 'https://www.reddit.com/r/sporforya/' },
];

export const Footer = () => {
  return (
    <div className="footer">
      <div className="logo-content">
        <img src={LogoLabel} alt="footer-logo" />
        <span>© 2023-2025 Sporti. All rights reserved.</span>
      </div>
      <div className="social-media">
        <span>Contact us on Social Media</span>
        <div className="social-media-icons">
          {socialMediaIcons.map((icon, index) => (
            <a href={icon.url} className="icon" key={index}>
              <icon.Icon width={20} height={20} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
